const GALLERYDATA = {
    Marked: [
        { img: "BeachColor.jpg", desc: "This was a mini-comic I created as an example for a comic contest I hosted on the Tapas froums.", title: "Team Xepher at the Beach" },
        { img: "Christ-Cringle.jpg", desc: "Jesus dressed as Santa Clause, made as a Christmas special for my webcomic, Marked.", title: "Christ Cringle" },
        { img: "RA-1.jpg", desc: "This is an old black and white image I made before my webcomic swtiched from black and white to color.", title: "Why Did This Happen?" },
        { img: "Group-Banner.jpg", desc: "The first banner to my webcomic Marked.", title: "Team Xepher Banner" },
        { img: "Gratcie-X-9-50-Chibi.jpg", desc: "Gratcie X-9 50 from my webcomic Marked in chibi form.", title: "Gratcie X-9 50 (Chibi)" },
        { img: "NewKenta-Thumbnai-Al.jpg", desc: "A thumbnail used on the Tapas website to try and draw in readers to Marked.", title: "Kenta Thumbnail" },
        { img: "BannerK.jpg", desc: "The second banner to my webcomic Marked.", title: "Kenta Apocolypse Banner" },
        { img: "Im-Sorry.jpg", desc: "An ominous piece I made of the main protagonist of Marked.", title: "I'm Sorry..." },
        { img: "Lilly-&-Sako-Easter.jpg", desc: "Happy Easter 2017.", title: "Sako & Lilly - Happy Easter" },
        { img: "Sako-Sweater.jpg", desc: "One of the protagonists from Marked asking an important question to an unknown individual.", title: "Do You... Think I'm Cute?" },
        { img: "Stary-Sky1.jpg", desc: "\"A hero looks onto the world he protects from evil.\"", title: "Starry Sky - Kenta" },
        { img: "Stary-Sky2.jpg", desc: "\"A villian looks onto a world burned by his destructive hands.\"", title: "Starry Sky - Ryden" },
        { img: "Sako-Valentine.jpg", desc: "Happy Valentines Day 2017.", title: "Valentines Day 2017 - Lineart" },
        { img: "Thanks-For-Subscribing-img.jpg", desc: "A piece originally made and used as a \"Thanks for subscribing\" image for people who subscrbibed to my Webcomic.", title: "The Angels That Protect This Broken World" },
        { img: "True_Fear.jpg", desc: "\"You shall know a fear greater than death itself!\"", title: "Experience True Fear" },
        { img: "Wallpaper.jpg", desc: "A wallpaper made in the first year of my webcomic.", title: "Mathusaleh Wallpaper" }
    ],
    Symbiotic: [
        { img: "Cover_Full.jpg", desc: "A poster/cover image made for my Visual Novel Symbiotic.", title: "Symbiotic Poster" },
        { img: "main-menu-background.jpg", desc: "This is the main menu CG used for my Visual Novel Symbiotic.", title: "Fighting Hand In Hand" },
        { img: "Zaydin_Theme2.jpg", desc: "\"This hellish world only offers sorrow and destruction...\"", title: "When Will It End?" },
        { img: "Zaydin.jpg", desc: "Concept art made for Zaydin.", title: "Zaydin Concept Art" },
        { img: "Zaydin-Symbiote.jpg", desc: "Concept art made for Zaydin in his Sym-Suit.", title: "Zaydin Sym-Suit Concept Art" },
        { img: "Tama.jpg", desc: "Concept art of Tama, Zaydin's symbiote partner.", title: "Tama Concept Art" },
        { img: "Elliosa.jpg", desc: "Concept art made for Elliosa.", title: "Elliosa Concept Art" },
        { img: "Elliosa-Symbiote.jpg", desc: "Concept art made for Elliosa in her Sym-Suit.", title: "Elliosa Sym-Suit Concept Art" },
        { img: "Pinkie.jpg", desc: "Concept art of Pinkie, Elliosa's symbiote partner.", title: "Pinkie Concept Art" },
        { img: "Kadin.jpg", desc: "Concept art made for Kadin.", title: "Kadin Concept Art" },
        { img: "Kadin-Symbiote.jpg", desc: "Concept art made for Kadin in his Sym-Suit.", title: "Kadin Sym-Suit Concept Art" },
        { img: "Spike.jpg", desc: "Concept art of Spike, Kadin's symbiote partner.", title: "Spike Concept Art" },
        { img: "Taya.jpg", desc: "Concept art made for Taya.", title: "Taya Concept Art" },
        { img: "Taya-Symbiote.jpg", desc: "Concept art made for Taya in her Sym-Suit.", title: "Taya Sym-Suit Concept Art" },
        { img: "Blaze.jpg", desc: "Concept art of Blaze, Taya's symbiote partner.", title: "Blaze Concept Art" },
        { img: "Neolinus_GrownUp.jpg", desc: "Concept art made for Neolinus.", title: "Neolinus Concept Art" },
        { img: "Alastair.jpg", desc: "Concept art made for Alastair.", title: "Alastair Concept Art" },
        { img: "Osmund.jpg", desc: "Concept art of Osmund, Alastair's symbiote partner.", title: "Osmund Concept Art" },
        { img: "Beelzebub_Ref.jpg", desc: "Concept art made for Beelzebub, a massive hulking beast and a destructive force to be reckoned with.", title: "Beelzebub Concept Art" },
        { img: "Symbiote1-FullControl.jpg", desc: "Symbiote and host working in tandum to fight against the things wrong with the world.", title: "Symbiote - Full Control" },
        { img: "Symbiote1-NoControl.jpg", desc: "The symbiote has taken full control of the host and is ready to rampage...", title: "Symbiote - No Control" },
        { img: "Neolinus-Office-Side(Zaydin)2.jpg", desc: "Zaydin in Neolinus' office.", title: "Symbiotic in-game CG - 1" },
        { img: "Simulation-Arena1-22.jpg", desc: "Zaydin yelling at Tama.", title: "Symbiotic in-game CG - 2" },
        { img: "Graveyard_8.jpg", desc: "Elliosa looking down at Kadin, concerned about him.", title: "Symbiotic in-game CG - 3" },
        { img: "Graveyard_13.jpg", desc: "Zaydin acting cocky while talking to Kadin about his cowerdice.", title: "Symbiotic in-game CG - 4" },
        { img: "Fight_44.jpg", desc: "Zaydin vs. Kadin - Zaydin slicing through a rock that was thrown at him.", title: "Symbiotic in-game CG - 5" },
        { img: "CallToAction_29.jpg", desc: "Elliosa amazed by Taya's sym-suit during training.", title: "Symbiotic in-game CG - 6" },
        { img: "ATF&ASO_14.jpg", desc: "Members of the Anti Sym Organization conversing about the war while on lookout.", title: "Symbiotic in-game CG - 7" },
        { img: "ATF&ASO_25.jpg", desc: "The mask of the leader, Malum.", title: "Symbiotic in-game CG - 8" },
        { img: "Tayas_Room14.jpg", desc: "Taya embarrassed that Zaydin is holding her after saving her from falling to the ground.", title: "Symbiotic in-game CG - 9" },
        { img: "Fun_Land_9.jpg", desc: "Elliosa explaing to Zaydin that she will always be there for him.", title: "Symbiotic in-game CG - 10" },
        { img: "Fun_Land_10.jpg", desc: "Zaydin embarrassed at Elliosa telling him she'll always be there for him.", title: "Symbiotic in-game CG - 11" }
    ],
    FightKing: [
        { img: "Andrey.jpg", desc: "Big, burly, and not the brightest apple in the bushel, but he's just so loveable.", title: "Andrey - Character Concept" },
        { img: "Arsenal(Cloak).jpg", desc: "The cloaked murder Arsenal.", title: "Arsenal(Cloak) - Character Concept" },
        { img: "Arsenal(No-Cloak).jpg", desc: "The uncloaked murder Arsenal.", title: "Arsenal(No Cloak) - Character Concept" },
        { img: "Arsenal-Sniper.jpg", desc: "Ready, aim, fire!", title: "Shoot to Kill" },
        { img: "Arsenal-vs-Ellie.jpg", desc: "This was created as part of a pitch to the webcomic platform Tapas in an attempt to make a premium comic out of it.", title: "Why Do You Look Like Her!?" },
        { img: "Ellie-w-Cloak.jpg", desc: "Ellie the vile wearing her trench coat.", title: "Ellie(Coat) - Character Concept" },
        { img: "Ellie-wo-Cloak.jpg", desc: "Elli the vile.", title: "Ellie(No Coat) - Character Concept" },
        { img: "Kira.jpg", desc: "Kira wearing her work maid uniform.", title: "Kira(Maid) - Character Concept" },
        { img: "Kira2.jpg", desc: "An angry kira that you just don't want to mess with.", title: "Kira(Angry) - Character Concept" },
        { img: "Koten(No-Shirt).jpg", desc: "The main protagonist of the story Koten... Without his shirt!", title: "Koten(No Shirt) - Character Concept" },
        { img: "Koten(Shirt).jpg", desc: "The main protagonist of the story Koten.", title: "Koten(Shirt) - Character Concept" },
        { img: "Koten-Fire.jpg", desc: "\"FLAME ON!\" Koten fired up and ready to beat someone into the ground.", title: "Koten(Flames) - Character Concept" },
        { img: "SPIKER1.jpg", desc: "\"Defender of peace, protector of the innocent, terror to the rest. The SPIKERSs will end your life in an instant.\"", title: "SPIKER" },
        { img: "SPIKER2.jpg", desc: "Another piece made in the hopes of turning \"Fight King\" into a premium comic on Tapas.", title: "The Protectors" }
    ],
    Naruto: [
        { img: "A-Kiss-After-War.jpg", desc: "An old piece made long ago.", title: "A Kiss After War" },
        { img: "Away-From-You.jpg", desc: "An OLDER piece made LONG ago.", title: "Away From You" },
        { img: "Introductions.jpg", desc: "This was made at the request of a fan on DevianART who wanted to have their character \"Zatoishi\" made.", title: "Introductions - (Neo Gen: Mini-Comic)" },
        { img: "Merry-Christmas-Naruto.jpg", desc: "Merry Christmas 2016.", title: "Merry Christmas Naruto" },
        { img: "Naruto-and-Sakura-A-Kiss-Before-War.jpg", desc: "Another old piece made long ago...", title: "A Kiss Before War" },
        { img: "Naruto-and-Sakura-Kiss-on-the-Forehead.jpg", desc: "\"Makes me want to kiss it.\"", title: "\"Your Forehead is so Wide and Charming\"" },
        { img: "Naruto-Ultimate-Bijuu-Mode2.jpg", desc: "The final form of the Ultimate Bijuu Mode from Naruto.", title: "Ultimate Bijuu Mode" },
        { img: "Shinachiku-Were-sorry.jpg", desc: "The end of Naruto made me very upset, thus this image was born from an image that @DatteBae on DevianART created.", title: "We're Sorry, We Love You" },
        { img: "Shinachiku.jpg", desc: "Son of Naruto and Sakura, all round cool kid Shinachiku of the Neo-Gen Naruto characters.", title: "Shinachiku - Character Concept" },
        { img: "ShinUnleashed.jpg", desc: "Shinachiku's loss of control foces him into a state of pure instinct, removing everything in his path.", title: "Shinachiku Unleashed - No Control" },
        { img: "ShinUnleashed2.jpg", desc: "The fully controled state of the unleashed form, with help from his mother.", title: "Shinachiku Unleashed - Full Control" },
        { img: "Siora.jpg", desc: "Team healer and phenomenal fighter Siora of the Neo-Gen Naruto characters.", title: "Siora - Character Concept" },
        { img: "Zatoishi-Awakened.jpg", desc: "\"The mark of Death will make you just like your father, hopefully.\"", title: "Zatoishi Curse Mark of Death - Unleashed" },
        { img: "Zatoishi-Unawakened.jpg", desc: "\"The mark of Death will make you just like your father, hopefully.\"", title: "Zatoishi Curse Mark of Death - Closed" },
        { img: "Zatoishi.jpg", desc: "Skilled sword user and the eyes of an Uchiha Zatoishi of the Neo-Gen Naruto Characters.", title: "Zatoishi - Character Concept" }
    ],
    Mascot: [
        { img: "Happy-New-Year.jpg", desc: "Happy New Year 2018.", title: "Happy New Year" },
        { img: "MascotAvatar-Flipped.png", desc: "This is the logo I made and use as my avatar.", title: "Zone Mascot Avatar - Colored" },
        { img: "Mascot1-Cape.jpg", desc: "Anti-Hero? Badass? Or just a wierdo in a costume? Hard to say.", title: "Zone Mascot - Cape" },
        { img: "Mascot1-NoCape.jpg", desc: "Anti-Hero? Badass? Or just a wierdo in a costume? Hard to say.", title: "Zone Mascot - No Cape" }
    ]
};

export { GALLERYDATA };