import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

function SiteGameModal({modal, toggle, proj, projData, site, selType, HNS}) {
    let description;
    if (projData[proj][selType][site].about) {
        // const parse = JSON.parse(projData[proj][selType][site].desc);
        // let engineList;
        // let techList;
        // let customList;
        // let thirdList;
        // if (parse["Engine Used"]) engineList = parse["Engine Used"].map((elm) => <li key={elm.id} data-id={elm.id}>{elm.name}</li>);
        // if (parse["Technologies Used"]) techList = parse["Technologies Used"].map((elm) => <li key={elm.id} data-id={elm.id}>{elm.name}</li>);
        // if (parse["Custom Plugins"]) customList = parse["Custom Plugins"].map((elm) => <li key={elm.id} data-id={elm.id}>{elm.name}</li>);
        // if (parse["3rd Party Plugins"]) thirdList = parse["3rd Party Plugins"].map((elm) => <li key={elm.id} data-id={elm.id}>{elm.name}</li>);
        
        // description = <React.Fragment>
        //         {
        //             parse.About ?
        //             <React.Fragment>
        //                 <h3>About:</h3>
        //                 <p className="modalDesc">{parse.About}</p>
        //             </React.Fragment> :
        //             ""
        //         }
        //         {
        //             parse["Engine Used"] ?
        //             <React.Fragment>
        //                 <h3>Engine Used:</h3>
        //                 <ul>
        //                     {engineList}
        //                 </ul>
        //             </React.Fragment> :
        //             ""
        //         }
        //         {
        //             parse["Technologies Used"] ? 
        //             <React.Fragment>
        //                 <h3>Technologies Used:</h3>
        //                 <ul>
        //                     {techList}
        //                 </ul>
        //             </React.Fragment> :
        //             ""
        //         }
        //         {
        //             parse["Custom Plugins"] ? 
        //             <React.Fragment>
        //                 <h3>Custom Plugins:</h3>
        //                 <ul>
        //                     {customList}
        //                 </ul>
        //             </React.Fragment> :
        //             ""
        //         }
        //         {
        //             parse["3rd Party Plugins"] ? 
        //             <React.Fragment>
        //                 <h3>3rd Party Plugins:</h3>
        //                 <ul>
        //                     {thirdList}
        //                 </ul>
        //             </React.Fragment> :
        //             ""
        //         }
        //     </React.Fragment>
        description = Object.values(projData[proj][selType][site]).map((elm, i) => {
            console.log(elm, i)
                return <React.Fragment key={i}>
                    {
                        elm.description ?
                        <React.Fragment>
                            <h3>About:</h3>
                            <p className='modalDesc'>{elm.description}</p>
                        </React.Fragment> :
                        ""
                    }
                    {
                        elm.tech ?
                        Object.entries(elm.tech).map(([k, v], ind) => 
                            <React.Fragment key={k + ind}>
                                {
                                    k === "engine" ?
                                    <React.Fragment>
                                        <h3>Engine Used:</h3>
                                        <ul>
                                            {v.map((e, i) => <li key={e + i} data-id={i}>{e}</li>)}
                                        </ul>
                                    </React.Fragment> :
                                    ""
                                }
                                {
                                    k === "lang" ?
                                    <React.Fragment>
                                        <h3>Technologies List:</h3>
                                        <ul>
                                            {v.map((e, i) => <li key={e + i} data-id={i}>{e}</li>)}
                                        </ul>
                                    </React.Fragment> :
                                    ""
                                }
                                {
                                    k === "pluginCustom" ?
                                    <React.Fragment>
                                        <h3>Custom Plugins:</h3>
                                        <ul>
                                            {v.map((e, i) => <li key={e + i} data-id={i}>{e}</li>)}
                                        </ul>
                                    </React.Fragment> :
                                    ""
                                }
                                {
                                    k === "pluginThird" ?
                                    <React.Fragment>
                                        <h3>3rd Party Plugins:</h3>
                                        <ul>
                                            {v.map((e, i) => <li key={e + i} data-id={i}>{e}</li>)}
                                        </ul>
                                    </React.Fragment> :
                                    ""
                                }
                            </React.Fragment>

                        ) :
                        ""
                    }
                </React.Fragment>
            });
    }
    console.log(description)
    return (
    <Modal id="infoModal" centered size="xl" isOpen={modal} toggle={toggle}>
        {projData[proj][selType][site] ?
        <React.Fragment>
            <ModalHeader toggle={toggle} className="bg-dark-cus modal-white-text">{projData[proj][selType][site].title}</ModalHeader>
            <ModalBody className="bg-dark-cus text-white-cus">
                <div className="container-fluid">
                    <div className="row">
                        <div className="media align-items-center">
                            <div id="img-holder" className="col-12 col-lg-8 text-center border-lg-right-2">
                                <button type="button" className="btn btn-secondary mr-3" style={{height: "100px"}} onClick={() => HNS(0)}><FontAwesomeIcon icon={faAngleLeft} /></button>
                                <img id="modalImg" className="img-fluid w-75 img-fluid-cus mb-3 mb-lg-0" src={`/images/${projData[proj][selType][site].img_folder ? `${projData[proj][selType][site].img_folder}/` : ""}${projData[proj][selType][site].img}`} alt="" />
                                <button type="button" className="btn btn-secondary ml-3" style={{height: "100px"}} onClick={() => HNS(1)}><FontAwesomeIcon icon={faAngleRight} /></button>
                            </div>

                            <div id="img-holder-full" className="img-holder row col-12 align-items-center justify-content-center text-center" style={{display: 'none'}}>
                                <button type="button" className="btn btn-secondary mr-3 btn-full-left-cus"><FontAwesomeIcon icon={faAngleLeft} /></button>
                                <span className="panel_gallery helper"></span>
                                <img id="modalImgFull" className="panel_gallery img-fluid p-3 img-fluid-cus" src={`/images/${projData[proj][selType][site].img_folder ? `${projData[proj][selType][site].img_folder}/` : ""}${projData[proj][selType][site].img}`} alt="" />
                                <button type="button" className="btn btn-secondary ml-3 btn-full-right-cus"><FontAwesomeIcon icon={faAngleRight} /></button>
                            </div>

                            <div className="d-none d-lg-block col-12 col-lg-4 text-center">
                                <span className="modalDesc">{description}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row d-block d-lg-none pt-3 border-top">
                        <div className="col-12 col-lg-6 align-self-center text-center">
                            <span className="modalDesc">{description}</span>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </React.Fragment> :
         ""}
    </Modal>
    );
}

export default SiteGameModal;