import React, { useEffect, useState } from 'react';
import { ProjectSelector } from '../HelperComponentsMain/ProjectsInfo';
import SiteGameModal from './HelperComponentsProj/SiteGameModal';

function Websites({proj, projData, modal, toggle}) {
    const [site, setSite] = useState(0);
    const [selType, setSelType] = useState("HTML");

    // async function fetchData(query, type) {
    //     await fetch('/api/projects', {
    //         method: 'post',
    //         body: JSON.stringify({query, type}),
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json'
    //         }
    //     })
    //         .then(res => res.json())
    //         .then(data => {
    //             setSelType(data);
    //         })
    //         .catch(err => console.error(err));
    // }

    // useEffect(() => {
    //     (async function() {
    //         await fetchData("websites", "HTML");
    //     })();
    // }, []);

    useEffect(() => {
        function arrowKeys(e) {
            switch(e.code) {
                case "ArrowLeft":
                    handleNext(0);
                    break;

                case "ArrowRight":
                    handleNext(1);
                    break;

                default: return;
            }
        }

        window.addEventListener('keydown', arrowKeys);

        return () => window.removeEventListener('keydown', arrowKeys);
    });

    function handleNext(num) {
        if (num === 0 && site === 0) return setSite(projData[proj][selType].length-1);
        else if (num === 1 && site === selType.length-1) return setSite(0);

        if (num === 0) setSite(site - 1);
        else if (num === 1) setSite(site + 1);
    }

    async function handleActive(e) {
        setSite(0)
        // let elm;
        // e.currentTarget.id ? elm = e.currentTarget.id : elm = e.currentTarget.parentNode.id;
        // Remember to setup a default incase the folder doesn't exist in the DB.

        // await fetchData("websites", elm);
        
        let elm = e.target.parentNode.dataset.name;
        if (e.target.dataset.name) elm = e.target.dataset.name;
        setSelType(elm);
        // switch(elm) {
        //     case "HTML":
        //         setSelType("HTML");
        //         break;
        //     case "React":
        //         setSelType("React");
        //         break;
        //     case "Wix":
        //         setSelType("Wix");
        //         break;
        //     default: 
        //         setSelType("HTML");
        //         console.log('The item you clicked is not does not have a gallery attached to it.');
        // }
    }

    return (
        <React.Fragment>
            <ProjectSelector
                proj={"websites"}
                projName={"Websites"}
                projData={projData}
                modal={modal}
                toggle={toggle}
                selType={selType}
                setSite={setSite}
                HandleActive={handleActive}
            />
            <SiteGameModal
                modal={modal}
                toggle={toggle}
                proj={proj}
                projData={projData}
                site={site}
                selType={selType}
                HNS={handleNext}
            />
        </React.Fragment>
    );
}

export default Websites