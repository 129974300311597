const ABOUTDATA = {
    img: 'images/Mascot/MascotAvatar-Flipped.png',
    text: {
        first: 'Hello, my name is Weston. I am a Web Developer with experience using Bootstrap, React, and React Native.',
        second: 'I\'m a web developer and artist with a knack for writing stories and working on large projects, usually alone. Often using my artistic skills to make stories in various artistic mediums such as games, webcomics, visual novels, etc.',
        third: 'In my late teens I became a developer on a Garry\'s Mod server creating plugins and doing maintenance on the server, but left due to the increasing toxicity within the server. I once had plans to create a new gamemode for Garry\'s Mod but due to time, a massive learning curve, and dealing with personal stuff it was an abandoned project, although at some point it would be interesting to continue.',
        fourth: 'While I was attending college I earned an Associates degree in art from Vol-State Community College and a web design certificate from Pellissippi State Community College. Originally this portfolio site was created as a project for a college assignment,  but has since been completely overhauled to take advantage of modern technologies like Bootstrap, JavaScript, React, JSX, and React Native.'
    },
    links: [
        {link: 'https://twitter.com/ZMProductionss', prefix: 'fab', icon: 'twitter-square'},
        {link: 'https://www.youtube.com/channel/UCqP9jxIClLPTcLgsO8JpInw', prefix: 'fab', icon: 'youtube-square'},
        {link: 'https://www.deviantart.com/midzm', prefix: 'fab', icon: 'deviantart'},
        {link: 'https://tapas.io/Mid25', prefix: 'fa', icon: 'palette'},
    ]
};

export { ABOUTDATA }