import React, { Component } from 'react';
import Main from './Main';
import Gallery from './Gallery';
import Projects from './Projects';
import About from './About';
// import Checklist from './Checklist.js';
import Websites from './Projects/Websites';
import Games from './Projects/Games';
import Webcomics from './Projects/Webcomics';
import Animations from './Projects/Animations';
import {
    Route,
    NavLink,
    BrowserRouter,
    Switch,
    Redirect
} from 'react-router-dom';
import { PROJECTDATA } from '../shared/ProjectData';
import { Collapse, Dropdown, DropdownMenu, DropdownToggle, Nav, NavbarToggler, NavItem } from 'reactstrap';
import Comics from './Projects/HelperComponentsProj/Comics';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class Navigation extends Component {
    constructor() {
        super();
        this.state = {
            isNavOpen: false,
            isDropOpen: false,
            isDropDown: false,
            projData: PROJECTDATA,
            modal: false,
            toggled: false,
            userCheck: "",
            isLogged: {
                logged: false,
                username: ""
            }
        };
    }

    onMouseEnter = () => this.setState({ isDropOpen: true });

    onMouseLeave = () => this.setState({ isDropOpen: false });

    // toggleNav = () => this.setState( prevState => {return { isNavOpen: !prevState.isNavOpen }} );

    toggle = () => this.setState({modal: !this.state.modal});

    // toggleLogin = () => this.setState((prevState) => {return {toggled: !prevState.toggled}});
    // authLogin = async (event) => {
    //     event.preventDefault();

    //     const username = document.querySelector('#username').value;
    //     const password = document.querySelector('#password').value;
    //     await fetch("/api/auth", {
    //         method: "post",
    //         body: JSON.stringify({username, password}),
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     })
    //         .then((res) => res.json())
    //         .then((data) => {
    //             this.setState(prevState => ({userCheck: data.message}))
    //             if (data.success === true) {
    //                 window.location.reload();
    //             }
    //         })
    //         .catch(err => console.error(err));
    // };

    // checkLog = () => {
    //     return new Promise(async (resolve, reject) => {
    //         await fetch("/api/logged", {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Accept': 'application/json'
    //             }
    //         })
    //             .then((res) => res.json())
    //             .then((data) => {
    //                 this.setState(prevState => ({
    //                     isLogged: {
    //                         logged: data.logged,
    //                         username: data.username
    //                     }
    //                 }));

    //                 return resolve();
    //             })
    //             .catch(err => reject(err));
    //     });
    // };

    // logout = async (event) => {
    //     await fetch("/api/logout", {
    //         method: "post"
    //     })
    //         .then(res => res.json())
    //         .then(data => window.location.reload())
    //         .catch(err => console.error(err));
    // };

    // async componentDidMount() {
    //     await this.checkLog();
    // }

    render() {
        return (
            <BrowserRouter>
                <nav className="navbar navbar-expand-sm navbar-dark bg-dark-cus sticky-top">
                    <NavLink to="/" exact className="navbar-brand"><img src={`/${process.env.PUBLIC_URL}images/MascotAvatar-Flipped.png`} alt="Logo" width="65" height="65" /></NavLink>
                    <NavbarToggler onClick={this.toggleNav} />
                    <Collapse id="navbarTop" isOpen={this.state.isNavOpen} navbar>
                        <Nav navbar>
                            <NavItem>
                                <NavLink to="/" exact activeClassName="active" className="nav-link">Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink to="/gallery" activeClassName="active" className="nav-link" href="gallery.html">Gallery</NavLink>
                            </NavItem>
                            <Dropdown isOpen={this.state.isDropOpen} onMouseOver={this.onMouseEnter} onMouseLeave={this.onMouseLeave} className="nav-item d-none d-sm-block">
                                <DropdownToggle tag="div" data-toggle="dropdown">
                                    <NavLink to="/projects" activeClassName="active" className="dropbtn nav-link dropdown-toggle" href="projects.html">Projects</NavLink>
                                </DropdownToggle>
                                <DropdownMenu className="p-0 m-0 bg-dark-cus border position-absolute">
                                    <NavLink to="/projects/websites" className="text-center dropdown-item text-white-cus border-bottom" href="projects/websites.html">Websites</NavLink>
                                    <NavLink to="/projects/games" className="text-center dropdown-item text-white-cus border-bottom" href="projects/games.html">Games</NavLink>
                                    <NavLink to="/projects/webcomics" className="text-center dropdown-item text-white-cus border-bottom" href="projects/webcomics.html">Webcomics</NavLink>
                                    <NavLink to="/projects/animations" className="text-center dropdown-item text-white-cus" href="projects/animations.html">Animations</NavLink>
                                </DropdownMenu>
                            </Dropdown>
                            <NavItem className="d-block d-sm-none">
                                <NavLink to="/projects" activeClassName="active" className="nav-link">Projects</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink to="/about" activeClassName="active" className="nav-link">About</NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink to="/checklist" activeClassName="active" className="nav-link">Checklist</NavLink>
                            </NavItem>
                            {!this.state.isLogged.logged ?
                            <li className="position-absolute d-flex" style={{right: 0}}>
                                <span onClick={() => this.toggleLogin()} className="nav-link" style={{cursor: "pointer"}}>Login</span>
                            </li> :
                            <li className="position-absolute d-flex" style={{right: 0}}>
                                <span className="nav-link" style={{cursor: "default"}}>{this.state.isLogged.username}</span>
                                <span onClick={() => this.logout()} className="nav-link" style={{cursor: "pointer"}}>Logout</span>
                            </li>
                            } */}
                        </Nav>
                    </Collapse>
                </nav>
                
                {/* <Modal
                        id="infoModal"
                        centered
                        size="md"
                        isOpen={this.state.toggled}
                        toggle={() => this.toggleLogin()}
                    >
                        <ModalHeader toggle={() => this.toggleLogin()} className="bg-dark-cus modal-white-text">Log In</ModalHeader>
                        <ModalBody className="bg-dark-cus text-white-cus">
                            <form action="/api/auth" onSubmit={(e) => {this.authLogin(e)}} className="w-100 d-flex flex-column align-items-center" method="post">
                                <div id="failedLogin" className="text-danger">{this.state.userCheck}</div>
                                <div className="form-group">
                                    <label htmlFor="username">Username:</label><br />
                                    <input id="username" className="form-control" type="text" name="username" placeholder="Username" required />
                                </div><br />
                                <div className="form-group">
                                    <label htmlFor="password">Password:</label><br />
                                    <input id="password" className="form-control" type="password" name="password" placeholder="Password" required />
                                </div><br />
                                <input type="submit" className="btn btn-outline-light w-25" value="login" />
                            </form>
                        </ModalBody>
                </Modal> */}
                <Switch>
                    <Route exact path="/" component={Main} />
                    <Route exact path="/gallery" render={() => <Gallery
                        // isLogged={this.state.isLogged}
                    />} />
                    <Route exact path="/projects" component={Projects} />
                    <Route exact path="/about" component={About} />
                    {/* <Route exact path="/checklist" component={Checklist} /> */}
                    
                    <Route path="/projects/websites" render={() => <Websites
                        proj={"websites"}
                        projData={this.state.projData}
                        modal={this.state.modal}
                        toggle={this.toggle}
                    />} />
                    <Route path="/projects/games" render={() => <Games
                        proj={"games"}
                        projData={this.state.projData}
                        modal={this.state.modal}
                        toggle={this.toggle}
                    />} />
                    <Route exact path="/projects/webcomics" render={() => <Webcomics
                        proj={"webcomics"}
                        projData={this.state.projData}
                        modal={this.state.modal}
                        toggle={this.toggle}
                    />} />

                        <Route path="/projects/webcomics/:dataName" render={() => <Comics
                            proj={"webcomics"}
                            projData={this.state.projData}
                            modal={this.state.modal}
                            toggle={this.toggle}
                        />} />

                    <Route path="/projects/animations" render={() => <Animations
                        proj={"animations"}
                        projData={this.state.projData}
                        modal={this.state.modal}
                        toggle={this.toggle}
                    />} />

                    <Redirect to="/" />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Navigation