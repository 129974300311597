import React, { Component } from 'react';
import { GallerySelector } from './HelperComponentsMain/GalleryInfo';
import { GALLERYDATA } from '../shared/GalleryData';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faAngleLeft, faAngleRight, faSpinner, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

class Gallery extends Component {
    constructor() {
        super();
        this.state = {
            canProceed: true,
            galName: "Marked",
            galData: GALLERYDATA,
            modal: false,
            imgIsFull: false,
            nextNum: 0,
            imageInfo: {
                img: '',
                desc: '',
                title: ''
            },
            active: {
                Marked: true
            },
            editInput: {
                title: false,
                titleEdit: "",
                desc: false,
                descEdit: ""
            },
            animate: false,
            fadeTimer: 3,
            isLoading: false
        }

        this.componentDidMount = this.componentDidMount.bind(this);
        this.setNextImage = this.setNextImage.bind(this);
        this.fullScreenPrefixer = this.fullScreenPrefixer.bind(this);
        this.setModalImg = this.setModalImg.bind(this);
        this.handleActive = this.handleActive.bind(this);
    }
    
    // fetchData = (query) => {
    //     return new Promise(async (resolve, reject) => {
    //         await fetch("/api/gallery", {
    //             method: "post",
    //             body: JSON.stringify({query}),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Accept': 'application/json'
    //             }
    //         })
    //             .then(res => res.json())
    //             .then(data => {
    //                 const obj = {};
    //                 for (let i = 0; i < data.length; i++) {
    //                     if (obj[data[i].folder] === undefined) {
    //                         obj[data[i].folder] = [];
    //                     }
    
    //                     obj[data[i].folder].push(data[i]);
    //                 }
    //                 obj[data[0].folder].sort((a, b) => a.pos - b.pos);
            
    //                 this.setState({galData: obj});
    //                 return resolve();
    //             })
    //             .catch(err => reject(err));
    //     });
    // }

    async componentDidMount() {
        // await this.fetchData("Marked");
        this.setState({
            imageInfo: {
                folder: this.state.galName,
                img: this.state.galData[this.state.galName].img,
                desc: this.state.galData[this.state.galName].desc,
                title: this.state.galData[this.state.galName].title
            }
        });
        
        const arrowKeys = e => {
            if (e.code === "ArrowLeft" && (!this.state.editInput.title && !this.state.editInput.desc)) {
                return this.handleNext("prev");
            } else if (e.code === "ArrowRight" && (!this.state.editInput.title && !this.state.editInput.desc)) {
                return this.handleNext("next");
            } else return;
        }

        window.addEventListener('keydown', arrowKeys);

        return () => window.removeEventListener('keydown', arrowKeys);
    }

    imgExists = () => {
        const img = document.querySelector('#modalImg');
        const imgIsLoaded = img.complete && img.naturalHeight !== 0;

        if (imgIsLoaded) {
            this.setState({
                animate: false,
                canProceed: true,
                isLoading: false
            });
        }
    }

    imgIsLoaded = () => {
        const img = document.querySelector('#modalImg');
        img.addEventListener("load", this.imgExists);
    }

    imgIsRemoved = () => {
        const img = document.querySelector('#modalImg');
        img.removeEventListener("load", this.imgExists);
    }

    setNextImage() {
        const { canProceed, galName, galData, nextNum, fadeTimer } = this.state;
        const fadeMili = fadeTimer*100;

        if (canProceed) {
            this.setState({
                canProceed: false
            }, () => {
                this.setState({
                    animate: true
                }, () => {
                    if (nextNum.current === nextNum.prev) {
                        this.setState({nextNum: {...nextNum, prev: nextNum.prev - 1}, canProceed: true}, () => this.setNextImage());
                    } else if (nextNum.next > nextNum.current + 1) {
                        this.setState({nextNum: {...nextNum, next: nextNum.next - 1}, canProceed: true}, () => this.setNextImage());
                    } else if (nextNum.next === nextNum.current) {
                        this.setState({nextNum: {...nextNum, next: nextNum.next + 1}, canProceed: true}, () => this.setNextImage());
                    } else {
                        setTimeout(() => {
                            this.setState({
                                imageInfo: {
                                    folder: galName,
                                    img: galData[galName][nextNum.current].img,
                                    desc: galData[galName][nextNum.current].desc,
                                    title: galData[galName][nextNum.current].title,
                                    id: galData[galName][nextNum.current].id
                                },
                                isLoading: true
                            });
                        }, fadeMili);
                    }
                });
            });
        }
    }

    fullScreenPrefixer() {
        let pfx = ["webkit", "moz", "ms", "o", ""];

        function RunPrefixMethod(obj, method) {
            let p = 0, m, t;
            while (p < pfx.length && !obj[m]) {
                m = method;
                if (pfx[p] === "") {
                    m = m.substr(0, 1).toLowerCase() + m.substr(1);
                }
                m = pfx[p] + m;
                t = typeof obj[m];
                if (t !== "undefined") {
                    pfx = [pfx[p]];
                    return (t === "function" ? obj[m]() : obj[m]);
                }
                p++;
            }
        }

        const imgHolder = document.querySelector('#img-holder-full');

        const exitHandler = () => {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                this.setState({imgIsFull: false});
                // canPress = false;
                document.removeEventListener('fullscreenchange', exitHandler);
                document.removeEventListener('webkitfullscreenchange', exitHandler);
                document.removeEventListener('mozfullscreenchange', exitHandler);
                document.removeEventListener('MSFullscreenChange', exitHandler);
            }
        }

        document.addEventListener('fullscreenchange', exitHandler);
        document.addEventListener('webkitfullscreenchange', exitHandler);
        document.addEventListener('mozfullscreenchange', exitHandler);
        document.addEventListener('MSFullscreenChange', exitHandler);
        
        if (RunPrefixMethod(document, "FullScreen") || RunPrefixMethod(document, "IsFullScreen")) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
            }
        }
        else {
            this.setState({imgIsFull: true});
            RunPrefixMethod(imgHolder, "RequestFullScreen");
        }
    }

    toggle = () => this.setState(prevState => ({modal: !prevState.modal}));

    setModalImg(e) {
        const { galName, galData } = this.state;
        const dataNum = parseInt(e.target.closest('[data-num]').dataset.num);
        const getSelectedImage = galData[galName].find((elm, i) => i === dataNum);
        const getSelectedIndex = galData[galName].findIndex((elm, i) => i === dataNum);
        
        this.setState({
            imageInfo: {
                folder: this.state.galName,
                img: getSelectedImage.img,
                desc: getSelectedImage.desc,
                title: getSelectedImage.title,
                id: getSelectedImage.id
            },
            editInput: {
                ...this.state.editInput,
                title: false,
                desc: false
            },
            nextNum: {
                current: getSelectedIndex,
                prev: getSelectedIndex - 1 !== -1 ?
                      getSelectedIndex - 1 :
                      galData[galName].length - 1,

                next: getSelectedIndex + 1 !== galData[galName].length ?
                      getSelectedIndex + 1 :
                      0
            }
        });
    }

    async handleActive(e) {
        let elm;
        e.target.id ? elm = e.target.id : elm = e.target.parentNode.id;
        // await this.fetchData(elm);

        this.setState({
            galName: elm,
            active: {
                [elm]: true
            }
        });
    }

    handleNext = (type) => {
        const { galName, galData } = this.state;
        const getCurrentIndex = this.state.nextNum.current;
        if (this.state.editInput.text || this.state.editInput.desc) {
            this.setState({
                editInput: {
                    ...this.state.editInput,
                    title: false,
                    desc: false
                }
            });
        }

        if (type === "prev") {
            this.setState({
                nextNum: {
                    current: this.state.nextNum.prev,
                    prev: getCurrentIndex - 2 <= -1 ?
                          galData[galName].length - 1 :
                          getCurrentIndex - 2,
                    next: this.state.nextNum.current
                }
            }, () => this.setNextImage());
        } else if (type === "next") {
            this.setState({
                nextNum: {
                    current: this.state.nextNum.next,
                    prev: this.state.nextNum.current,
                    next: getCurrentIndex + 2 >= galData[galName].length ?
                          0 :
                          getCurrentIndex + 2
                }
            }, () => this.setNextImage());
        } else return console.error("You dun goofed...");
    }

    // handleSubmit = async (event) => {
    //     const { editInput, galData, galName, imageInfo } = this.state;
    //     event.preventDefault();

    //     if (editInput.title) {
    //         const title = document.querySelector("[name=edit-title]");
    //         const titleValue = editInput.titleEdit;
    //         const titleId = title.dataset.imageid;
    //         const body = {type: "title", value: titleValue, id: titleId};

    //         await fetch("/api/gallery/updateItem", {
    //             method: 'post',
    //             body: JSON.stringify(body),
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         })
    //             .then(res => res.json())
    //             .then(data => {
    //                 const findIndex = galData[galName].findIndex(elm => elm.id === parseInt(titleId));
    //                 this.setState({
    //                     galData: {
    //                         [galName]: [
    //                             ...galData[galName],
    //                             galData[galName][findIndex].title = titleValue
    //                         ]
    //                     },
    //                     imageInfo: {
    //                         ...imageInfo,
    //                         title: titleValue
    //                     },
    //                     editInput: {
    //                         ...editInput,
    //                         title: false
    //                     }
    //                 }, () => Promise.resolve());
    //             })
    //             .catch(err => console.error(err));
    //     } else if (editInput.desc) {
    //         const desc = document.querySelector("[name=edit-desc]");
    //         const descValue = editInput.descEdit;
    //         const descId = desc.dataset.imageid;
    //         const body = {type: "desc", value: descValue, id: descId};
            
    //         await fetch("/api/gallery/updateItem", {
    //             method: 'post',
    //             body: JSON.stringify(body),
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         })
    //             .then(res => res.json())
    //             .then(data => {
    //                 const findIndex = galData[galName].findIndex(elm => elm.id === parseInt(descId));
    //                 this.setState({
    //                     galData: {
    //                         [galName]: [
    //                             ...galData[galName],
    //                             galData[galName][findIndex].desc = descValue
    //                         ]
    //                     },
    //                     imageInfo: {
    //                         ...imageInfo,
    //                         desc: descValue
    //                     },
    //                     editInput: {
    //                         ...editInput,
    //                         desc: false
    //                     }
    //                 }, () => Promise.resolve());
    //             })
    //             .catch(err => console.error(err));
    //     }
    // }

    handleChange = (event, type) => {
        if (type === "title") {
            this.setState({editInput: {...this.state.editInput, titleEdit: event.target.value}});
        } else if (type === "desc") {
            this.setState({editInput: {...this.state.editInput, descEdit: event.target.value}});
        } else console.error("You dun goofed...");
    }

    render() {
        const { galName, galData, modal, imgIsFull, imageInfo, active, fadeTimer, isLoading, editInput } = this.state;
        const fade = {
            transition: `opacity 0.${fadeTimer}s`
        }

        return (
            <div id="gallery">
                {
                    !Object.keys(this.state.galData).length ?
                    <div className="modal-white-text text-center w-100 mt-5">
                        <h1>Loading...</h1>
                    </div> :
                    <GallerySelector
                        // isLogged={this.props.isLogged}
                        HandleActive={this.handleActive}
                        Active={active}
                        GalData={galData}
                        GalName={galName}
                        Toggle={this.toggle}
                        SetModalImg={this.setModalImg}
                    />
                }

                <Modal
                    id="infoModal"
                    centered
                    size="xl"
                    isOpen={modal}
                    toggle={this.toggle}
                    onOpened={this.imgIsLoaded}
                    onClosed={this.imgIsRemoved}
                >
                    <button type="button" className="d-block btn btn-outline-light position-absolute" style={{left: "0.8rem", top: "0.8rem"}} onClick={this.fullScreenPrefixer}><FontAwesomeIcon icon={faExpand} /></button>
                    <ModalHeader toggle={this.toggle} className="bg-dark-cus modal-white-text pl-6">{imageInfo.title}
                        {/* {
                            (!this.props.isLogged.logged || (this.props.isLogged.logged && !editInput.title)) ?
                            imageInfo.title :
                            <form autoComplete="off" action="/api/gallery/updateItem" onSubmit={async (e) => {await this.handleSubmit(e, "title");}} className="w-100 align-items-center" method="post" >
                                <div className="input-group">
                                    <input className="form-control" type="text" name="edit-title" value={editInput.titleEdit} data-imageid={imageInfo.id} onChange={(e) => this.handleChange(e, "title")} />
                                    <div className="input-group-append">
                                        <input type="submit" className="btn btn-outline-light" />
                                        <input type="button" className="btn btn-outline-light" onClick={() => {
                                            this.setState({
                                                editInput: {
                                                    ...editInput,
                                                    title: false,
                                                    titleEdit: ""
                                                }
                                            })
                                        }} value="Cancel" />
                                    </div>
                                </div>
                            </form>
                        } */}
                        <span></span>
                        {/* {
                            !this.props.isLogged.logged || (this.props.isLogged.logged && editInput.title) ?
                            <span></span> :
                            <span>&nbsp;
                                <FontAwesomeIcon
                                    onClick={() => {this.setState({
                                        editInput: {
                                            ...editInput,
                                            desc: false,
                                            title: true,
                                            titleEdit: imageInfo.title
                                        }
                                    })}}
                                    role="button"
                                    icon={faPencilAlt}
                                />
                            </span>
                        } */}
                        </ModalHeader>
                    <ModalBody className="bg-dark-cus text-white-cus">
                        <div className="container-fluid">
                            <div className="row h-100">
                                <div className="media align-items-center h-100">
                                    <div id="img-holder" className="col-12 col-lg-8 text-center border-lg-right-2 h-100" style={!imgIsFull ? {display: "block"} : {display: "none"}}>
                                        <button type="button" className="btn btn-secondary mr-3" style={{height: "100px"}} onClick={() => this.handleNext("prev")}><FontAwesomeIcon icon={faAngleLeft} /></button>
                                        { isLoading ? <FontAwesomeIcon className="position-absolute text-center w-75 my-auto" style={{top: 0, bottom: 0}} icon={faSpinner} size="3x" pulse /> : <span /> }
                                        <img id="modalImg" style={fade} className={`img-fluid w-75 img-fluid-cus mb-3 mb-lg-0 ${this.state.animate ? 'fadeOut' : 'fadeIn'}`} src={`/images/${imageInfo.folder}/${imageInfo.img}`} data-id={imageInfo.id} alt="" />
                                        <button type="button" className="btn btn-secondary ml-3" style={{height: "100px"}} onClick={() => this.handleNext("next")}><FontAwesomeIcon icon={faAngleRight} /></button>
                                    </div>

                                    <div id="img-holder-full" className="img-holder row col-12 align-items-center justify-content-center text-center" style={imgIsFull ? {display: "flex"} : {display: "none"}}>
                                        <button type="button" className="d-block btn btn-outline-light position-absolute" style={{left: "1rem", top: "1rem", zIndex: 10}} onClick={this.fullScreenPrefixer}><FontAwesomeIcon icon={faExpand} size="3x" /></button>
                                        <button type="button" className="btn btn-secondary mr-3 btn-full-left-cus" onClick={() => this.handleNext("prev")}><FontAwesomeIcon icon={faAngleLeft} /></button>
                                        <span className="panel_gallery helper"></span>
                                        <img id="modalImgFull" style={fade} className={`panel_gallery img-fluid p-3 img-fluid-cus ${this.state.animate ? 'fadeOut' : 'fadeIn'}`} src={`/images/${imageInfo.folder}/${imageInfo.img}`} data-id={imageInfo.id} alt="" />
                                        { isLoading ? <FontAwesomeIcon className="position-absolute text-center w-75 my-auto" style={{top: 0, bottom: 0}} icon={faSpinner} size="3x" pulse /> : <span /> }
                                        <button type="button" className="btn btn-secondary ml-3 btn-full-right-cus" onClick={() => this.handleNext("next")}><FontAwesomeIcon icon={faAngleRight} /></button>
                                    </div>

                                    <div className="d-none d-lg-block col-12 col-lg-4 text-center">
                                        <p className="modalDesc">{imageInfo.desc}</p>
                                        {/* {
                                            (!this.props.isLogged.logged || (this.props.isLogged.logged && !editInput.desc)) ?
                                            <p className="modalDesc">{imageInfo.desc}</p> :
                                            <form autoComplete="off" action="/api/gallery/updateItem" onSubmit={async (e) => {await this.handleSubmit(e, "desc");}} className="w-100 d-flex flex-column align-items-center" method="post" >
                                                <div className="input-group">
                                                    <textarea className="form-control" style={{height: '15vh'}} type="text" name="edit-desc" value={editInput.descEdit} data-imageid={imageInfo.id} onChange={(e) => this.handleChange(e, "desc")} />
                                                </div>
                                                <br />
                                                <div className="input-group d-flex justify-content-center">
                                                    <div className="input-group-prepend">
                                                        <input type="submit" className="btn btn-outline-light" />
                                                    </div>
                                                        <input type="button" className="btn btn-outline-light" onClick={() => {
                                                            this.setState({
                                                                editInput: {
                                                                    ...editInput,
                                                                    desc: false,
                                                                    descEdit: ""
                                                                }
                                                            })
                                                        }} value="Cancel" />
                                                </div>
                                            </form>
                                        } */}
                                        <span></span>
                                        {/* {
                                            !this.props.isLogged.logged || (this.props.isLogged.logged && editInput.desc) ?
                                            <span></span> :
                                            <FontAwesomeIcon
                                                onClick={() => {
                                                    this.setState({
                                                        editInput: {
                                                            ...editInput,
                                                            title: false,
                                                            desc: true,
                                                            descEdit: imageInfo.desc
                                                        }
                                                    });
                                                }}
                                                role="button"
                                                icon={faPencilAlt}
                                            />
                                        } */}
                                    </div>
                                </div>
                            </div>

                            <div className="row d-block d-lg-none pt-3 border-top">
                                <div className="col-12 col-lg-6 align-self-center text-center">
                                    <p className="modalDesc">{imageInfo.desc}</p>
                                    {/* {
                                        (!this.props.isLogged.logged || (this.props.isLogged.logged && !editInput.desc)) ?
                                        <p className="modalDesc">{imageInfo.desc}</p> :
                                        <form autoComplete="off" action="/api/gallery/updateItem" onSubmit={async (e) => {await this.handleSubmit(e, "desc");}} className="w-100 d-flex flex-column align-items-center" method="post" >
                                            <div className="input-group">
                                                <textarea className="form-control" style={{height: '15vh'}} type="text" name="edit-desc" value={editInput.descEdit} data-imageid={imageInfo.id} onChange={(e) => this.handleChange(e, "desc")} />
                                            </div>
                                            <br />
                                            <div className="input-group d-flex justify-content-center">
                                                <div className="input-group-prepend">
                                                    <input type="submit" className="btn btn-outline-light" />
                                                </div>
                                                    <input type="button" className="btn btn-outline-light" onClick={() => {
                                                        this.setState({
                                                            editInput: {
                                                                ...editInput,
                                                                desc: false,
                                                                descEdit: ""
                                                            }
                                                        })
                                                    }} value="Cancel" />
                                            </div>
                                        </form>
                                    } */}
                                    <span></span>
                                    {/* {
                                        !this.props.isLogged.logged || (this.props.isLogged.logged && editInput.desc) ?
                                        <span></span> :
                                        <FontAwesomeIcon
                                            onClick={() => {
                                                this.setState({
                                                    editInput: {
                                                        ...editInput,
                                                        title: false,
                                                        desc: true,
                                                        descEdit: imageInfo.desc
                                                    }
                                                });
                                            }}
                                            role="button"
                                            icon={faPencilAlt}
                                        />
                                    } */}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default Gallery;