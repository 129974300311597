import React, { Component } from 'react';
import Anime from 'react-anime';
import { Collapse, Dropdown, NavbarToggler } from 'reactstrap';

function GalleryInfo(props) {
    let galName = props.name;
    console.log(props.galleryData)
    const galInfo = props.galleryData[galName].map((elm, index) => (
        <div key={index} data-num={index} data-name={elm.title} onClick={(e) => {
                props.toggle();
                props.setImg(e);
            }}>
            <img className="img-fluid img-thumbnail image" src={`/${process.env.PUBLIC_URL}images/${galName}/thumb/${elm.img}`} alt={elm.title} />
            <div className="middle">
                <div className="text">{elm.title}</div>
            </div>
        </div>
    ));
    
    return (
        <div id="imageList" className="column-4">
            <Anime  className="cont "
                    duration={750}
                    delay={(el, i) => i * 50}
                    opacity={[0, 1]}
                    scale={[1, 1.2, 1]}
                    easing='easeInOutSine'>
            {galInfo}
            </Anime>
        </div>
    );
}

class GallerySiderBar extends Component {
    constructor() {
        super();
        this.state = {
            isSideBarOpen: false
        }

    }

    toggleSideBar = (check) => {if (check) this.setState(prevState => { return { isSideBarOpen:!prevState.isSideBarOpen } })};
    
    render() {
        return (
            <Dropdown className="row h-sm-100 m-0 position-absolute w-100">
                <NavbarToggler onClick={this.toggleSideBar} className="mt-2 mb-2 ml-2" style={{zIndex: 6}} />
                <Collapse id="infoNames" className="navbar-collapse" isOpen={this.state.isSideBarOpen}>
                    <div className="col-sm-3 p-0 bg-dark-cus text-center border-sm-right-2" style={{zIndex: 6}}>
                        <div className="container">
                            <div className="row">
                                    <button id="Marked" onClick={async (e) => {await this.props.HandleActive(e); window.innerWidth <= 575 ? this.toggleSideBar(true) : this.toggleSideBar();}} className={`btn btn-noLine col-sm-12 nav-link text-a-cus font-2 rounded-0 ${this.props.Active.Marked ? "active" : ""}`} data-name="Marked"><h4>Marked</h4></button>
                                    <button id="Symbiotic" onClick={async (e) => {await this.props.HandleActive(e); window.innerWidth <= 575 ? this.toggleSideBar(true) : this.toggleSideBar();}} className={`btn btn-noLine col-sm-12 nav-link text-a-cus font-2 rounded-0 ${this.props.Active.Symbiotic ? "active" : ""}`} data-name="Symbiotic"><h4>Symbiotic</h4></button>
                                    <button id="FightKing" onClick={async (e) => {await this.props.HandleActive(e); window.innerWidth <= 575 ? this.toggleSideBar(true) : this.toggleSideBar();}} className={`btn btn-noLine col-sm-12 nav-link text-a-cus font-2 rounded-0 ${this.props.Active.FightKing ? "active" : ""}`} data-name="FightKing"><h4>Fight King (Concept)</h4></button>
                                    <button id="Naruto" onClick={async (e) => {await this.props.HandleActive(e); window.innerWidth <= 575 ? this.toggleSideBar(true) : this.toggleSideBar();}} className={`btn btn-noLine col-sm-12 nav-link text-a-cus font-2 rounded-0 ${this.props.Active.Naruto ? "active" : ""}`} data-name="Naruto"><h4>Naruto</h4></button>
                                    <button id="Mascot" onClick={async (e) => {await this.props.HandleActive(e); window.innerWidth <= 575 ? this.toggleSideBar(true) : this.toggleSideBar();}} className={`btn btn-noLine col-sm-12 nav-link text-a-cus font-2 rounded-0 ${this.props.Active.Mascot ? "active" : ""}`} data-name="Mascot"><h4>Mascot</h4></button>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </Dropdown>
        )
    }
}

class GallerySelector extends Component {
    constructor() {
        super();
        this.state = {
            imgName: "",

            folderCheck: "",
            imageCheck: "",
            titleCheck: "",
            descCheck: "",
            positionCheck: "",
            fileUpload: false
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.props.Active === nextProps.Active &&
            // this.props.isLogged.logged === nextProps.isLogged.logged &&
            this.state.titleCheck === nextState.titleCheck &&
            this.state.folderCheck === nextState.folderCheck &&
            this.state.descCheck === nextState.descCheck &&
            this.state.imageCheck === nextState.imageCheck &&
            this.state.fileUpload === nextState.fileUpload
        ) return false;
        else return true;
    }
    
    handleChange = (e) => {
        const input = document.querySelector("#folder");
        input.value = e.target.value;
    }

    // handleSubmit = async (e) => {
    //     e.preventDefault();
    //     // Variables
    //     const folder = document.querySelector("#folder").value;
    //     const pos = document.querySelector("#pos").value;
    //     let img = this.state.imgName;
    //     const title = document.querySelector("#title").value;
    //     const desc = document.querySelector("#desc").value;
    //     const reg = new RegExp(/^\d+$/);
    //     let imgUpload;

    //     // Folder required alert.
    //     if (folder.includes(" ")) return this.setState({folderCheck: "Folders must be a single word or seperated by a dash(-) or underscore(_)."});
    //     else if (folder === "") return this.setState({folderCheck: "Folders cannot be left empty."});

    //     // Position required alert.
    //     if (pos.includes(" ") || !reg.test(pos)) this.setState({positionCheck: "Positions must be a number"});
    //     else if (pos === "") return this.setState({positionCheck: "Positions cannot be left empty."});
        
    //     // Image required alert.
    //     if (img.includes(" ") && !this.state.fileUpload) return this.setState({imageCheck: "Images cannot contain an empty space."});
    //     else if (img.includes(" ") && this.state.fileUpload) img = img.split(" ").join("-");
    //     else if (!img.includes(".jpg") && !img.includes(".png") && !img.includes(".gif") && !img.includes(".jpeg")) return this.setState({imageCheck: "Images must have an ending prefix of .jpg, .jpeg, .png, or .gif."});
    //     else if (img === "") return this.setState({imageCheck: "Images cannot be left empty."});
        
    //     // Title required alert.
    //     if (title === "") return this.setState({titleCheck: "Titles cannot be left empty."});
    //     // Description required alert.
    //     if (desc === "") return this.setState({descCheck: "Descriptions cannot be left empty."});

    //     // Multiple images, titles, and descriptions.
    //     if (
    //         ( img.includes(",") || title.includes(",") || desc.includes("-,-") ) &&
    //         ( !img.includes(",") || !title.includes(",") || !desc.includes("-,-") )
    //     ) return this.setState({ imageCheck: "Multiple images must also have multiple titles and descriptions. Format: title,title desc-,-desc" });

    //     if (this.state.fileUpload) {
    //         imgUpload = document.querySelector("#imgFile").files[0];

    //         let options = {
    //             method: "post"
    //         }

    //         options.body = new FormData();
    //         options.body.append("folder", folder);
    //         options.body.append("pos", pos);
    //         options.body.append("img", img);
    //         options.body.append("title", title);
    //         options.body.append("desc", desc);
    //         options.body.append("file", imgUpload);
    //         await fetch("/api/gallery/addItem", options)
    //             .then(res => res.json())
    //             .then(data => console.log(data))
    //             .catch(err => console.error(err));
    //     } else {
    //         // Fetch the gallery api and add the item.
    //         await fetch("/api/gallery/addItem", {
    //             method: "post",
    //             body: JSON.stringify({folder, img, title, desc, pos}),
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         })
    //             .then(res => res.json())
    //             .catch(err => console.error(err));
    //     }
    // }
    
    render() {
        console.log(this.state.imgName)
        return (
            <div className="container m-0 p-0 h-sm-100 navbar-expand-sm navbar-dark">
                <div className="row h-sm-100 d-none d-sm-block m-0-cus collapse navbar-collapse position-absolute w-100">
                    <div className="col-sm-3 h-100 p-0 bg-dark-cus text-center border-sm-right-2"></div>
                </div>

                <GallerySiderBar
                    HandleActive={(e) => this.props.HandleActive(e)}
                    Active={this.props.Active}
                />

                <div className="row m-0 position-absolute w-100 scrollDiv galleryDiv">
                    <div className="d-none d-sm-block col-sm-3"></div>
                    <div className="col col-sm-9">
                        <div className="container p-3">
                            <div></div>
                            {/* {
                                !this.props.isLogged.logged ?
                                <div></div> :
                                <div className="row form-gallery">
                                    <form id="addGalleryItem" autoComplete="off" action="/api/gallery/addItem" onSubmit={(e) => {this.handleSubmit(e, );}} className="w-100 d-flex flex-column align-items-center mb-3" method="post">
                                        <div className="form-group w-50">
                                            <label htmlFor="folder" className="w-100">
                                                Folder Name:<br />
                                                <input id="folder" name="folder" className="form-control" type="text" placeholder="Folder Name" required /><br />
                                                <select onChange={(e) => {this.handleChange(e)}} defaultValue="none" className="form-control">
                                                    <option disabled value="none">Choose Existing Folder</option>
                                                    <option value="Marked">Marked</option>
                                                    <option value="Symbiotic">Symbiotic</option>
                                                    <option value="FightKing">Fight King</option>
                                                    <option value="Naruto">Naruto</option>
                                                    <option value="Mascot">Mascot</option>
                                                </select>
                                            </label>
                                        </div>
                                        <div id="badPosition" className="text-danger">{this.state.positionCheck}</div>
                                        <div className="form-group w-50">
                                            <label htmlFor="pos" className="w-100">
                                                Position:<br />
                                                <input id="pos" name="pos" className="form-control" type="text" placeholder="Position (number)" required />
                                                (images in selected folder: {this.props.GalData[this.props.GalName].length} (by array length, keep this in mind))
                                            </label>
                                        </div>
                                        <div id="badFolder" className="text-danger">{this.state.folderCheck}</div>
                                        <div className="form-group w-50">
                                            <label htmlFor="image" className="w-100">
                                                Image:<br />
                                                <input id="image" name="image" className="form-control" type="text" placeholder="Image" onChange={(e) => this.setState({imgName: e.target.value})} required={!this.state.fileUpload} disabled={this.state.fileUpload} />
                                            </label>
                                            <input type="file" id="imgFile" onChange={(e) => {!e.target.value ? this.setState({fileUpload: false, imgName: document.querySelector("#image").value}) : this.setState({fileUpload: true, imgName: e.target.files[0].name})}} className="form-control-file" name="imgFile" />
                                        </div>
                                        <div id="badImage" className="text-danger">{this.state.imageCheck}</div>
                                        <div className="form-group w-50">
                                            <label htmlFor="title" className="w-100">
                                                Title:<br />
                                                <input id="title" name="title" className="form-control" type="text" placeholder="Title" required />
                                            </label>
                                        </div>
                                        <div id="badTitle" className="text-danger">{this.state.titleCheck}</div>
                                        <div className="form-group w-75">
                                            <label htmlFor="desc" className="w-100">
                                                Description:<br />
                                                <textarea form="addGalleryItem" id="desc" name="desc" className="form-control" type="text" placeholder="Description for the image..." required />
                                            </label>
                                        </div>
                                        <div id="badDesc" className="text-danger">{this.state.descCheck}</div>

                                        <br />

                                        <input type="submit" className="btn btn-outline-light w-25" value="Add to Gallery" />
                                    </form>
                                </div>
                            } */}
                            <div className="row">
                                <div className="col-12 text-light text-center mb-3">
                                    <h1>Gallery</h1>
                                </div>
                            </div>
                            <div className="column-1">
                                <GalleryInfo
                                    galleryData={this.props.GalData}
                                    name={this.props.GalName}
                                    toggle={this.props.Toggle}
                                    setImg={this.props.SetModalImg}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { GalleryInfo, GallerySelector };